import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "p-6 text-center bg-white" }
const _hoisted_2 = {
  class: "text-2xl",
  for: "wort_select"
}
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('concordance.select', {
          sura: _ctx.$route.params.sura,
          verse: _ctx.$route.params.verse,
        })), 1),
    _withDirectives(_createElementVNode("select", {
      class: "ml-2 text-2xl bg-blue-light border",
      id: "word_select",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.navigate && _ctx.navigate(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arabicVerse, (word, index) => {
        return (_openBlock(), _createElementBlock("option", {
          key: index,
          value: index + 1
        }, _toDisplayString(`${index + 1}: ${_ctx.transcription[index]}`), 9, _hoisted_3))
      }), 128))
    ], 544), [
      [_vModelSelect, _ctx.selected]
    ])
  ]))
}